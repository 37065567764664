<template>
  <div class="vw-100 vh-100 flex flex-row">
    <div class="position-absolute top-0 start-0 text-light px-1">
      {{ version }}
    </div>
    <div class="w-full sm:min-w-[250px] sm:w-[250px] bg-primary-950 p-3">
      <div class="flex flex-col h-full">
        <div class="mt-2 mb-3 p-3 bg-light rounded" v-if="$theme === 'csa'">
          <img src="../assets/csa.svg" class="h-32">
        </div>
        <div class="mt-2 mb-3 rounded flex justify-center" v-else-if="$theme === 'sara'">
          <img src="../assets/logo_sara.png" class="h-32">
        </div>
        <div class="lh-1 text-light text-center">
          <div class="fs-5 fw-bolder">¡Bienvenido!</div>
        </div>
        <div class="flex-grow-1 mt-3">
          <div>
            <label class="form-label mb-0 text-light">Nombre de usuario o email</label>
            <input name="email" type="text" class="form-control" v-model="email">
            <form-error-login name="user_identifier" :errors="errors" class="mt-1"></form-error-login>
          </div>
          <div>
            <label class="form-label mb-0 text-light">Contraseña</label>
            <input name="password" type="password" class="form-control" v-model="password"
                   @keyup.enter="login">
            <form-error-login name="password" :errors="errors" class="mt-1"></form-error-login>
          </div>
          <div class="text-center">
            <button :disabled="working"
                    class="btn btn-outline-light w-100 my-3" @click="login">
              Iniciar sesión
            </button>
            <span class="">
              <router-link to="/recuperarContrasena" class="link-light">¿Olvidaste tu contraseña?</router-link>
            </span>
          </div>
        </div>
        <div class="p-3">
          <img src="../assets/dev_by_white.png" class="max-h-10 max-w-full">
        </div>
      </div>
    </div>

    <div class="hidden sm:block grow relative bg-[url('bgpattern.webp')] bg-repeat w-full h-full opacity-20">
    </div>
    <SideBannerMain/>
  </div>
</template>

<script>
import axios from "axios";
import FormErrorLogin from "../components/utils/FormErrorLogin.vue";
import {VERSION} from "../code/app";
import SideBannerMain from "@/components/SideBannerMain.vue";

export default {
  name: 'LoginView',
  components: {
    SideBannerMain,
    FormErrorLogin
  },
  data() {
    return {
      project_name: import.meta.env.VITE_NAME ?? 'Tresfactorial',
      errors: {},
      email: null,
      password: null,
      working: false,
      version: VERSION,
    }
  },
  methods: {
    login() {
      this.errors = [];
      this.working = true;
      axios.post('/login', {
        user_identifier: this.email,
        password: this.password,
      }).then(response => {
        this.$router.replace('/');
      }).catch(error => {
        this.errors = error?.response?.data?.errors ?? {};
        this.working = false;
      });
    }
  }
}
</script>
