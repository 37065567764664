<template>
  <div id="app" class="vw-100 vh-100 flex flex-row">
    <main-menu v-if="is_logged_in"></main-menu>

    <div :class="{'flex-grow-1': !['/', '/login'].includes($route.path)}" class="bg-neutral-50 grow"
         style="max-height: 100%; overflow-y: auto">
      <RouterView/>
    </div>

    <flash-alert></flash-alert>
  </div>
</template>

<script>
import {mapState} from "pinia";
import {useUserStore} from './stores/user'
import MainMenu from "./components/home/MainMenu.vue";

export default {
  name: 'App',
  components: {
    MainMenu,
  },
  computed: {
    ...mapState(useUserStore, ['is_logged_in'])
  },
  watch: {
    $route(to, from) {
      //always clear the pusher channels when we change route
      for (let channel of this.$pusher.allChannels()) {
        channel.unsubscribe();
        channel.unbind_all();
      }
    }
  },
}
</script>
